<script>
// import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
import { BFormFile } from 'bootstrap-vue'
// import DatePicker from "vue2-datepicker";
// import { _ } from "vue-underscore";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "นำเข้าอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    Multiselect,
    BFormFile
    // DatePicker,
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      loading: false,
      isLoading: false,

      title: "นำเข้าอะไหล่",
      rowsImport: [],
      filePart: "",
      resalert: "",
      dismissSecs: 3,
      filePackage: null,
      modelId: "",
      rowsImportPackage: [],
      rowsModels: [],
      dismissCountDown: 0,
      items: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "นำเข้าอะไหล่",
          active: true,
        },
      ],
      userBranch: "",
    };
  },
  validations: {},
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.getDataModels();
  },
  created() {},
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    postParts: function(item) {
      this.loading = true;
      const bodyFormData = new FormData();
      bodyFormData.append("branchId", this.userBranchId);
      bodyFormData.append("partCode", item.partCode);
      bodyFormData.append("codeType", "EX");
      bodyFormData.append("nameTh", item.nameTh);
      bodyFormData.append(
        "costPrice",
        item.costPrice.replace(/,/g, "") != "-"
          ? item.costPrice.replace(/,/g, "")
          : 0
      );
      bodyFormData.append(
        "sellPrice",
        item.sellPrice.replace(/,/g, "") != "-"
          ? item.sellPrice.replace(/,/g, "")
          : 0
      );
      useNetw
        .post("api/part/store", bodyFormData)
        .then((response) => {
          //  console.log(response);
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          this.showAlert(err.response.data.message);
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataModels: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-model", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsModels = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    importData: function() {
      this.loading = true;
      const formDataPart = new FormData();
      formDataPart.append("filePart", this.filePart);
      formDataPart.append("branchId", this.userBranchId);
      useNetw
        .post("api/importpartdata", formDataPart)
        .then((response) => {
          this.rowsImport = response.data;
          //   console.log(response)
          // const arr = [];
          this.rowsImport.forEach((item) => {
            //     arr.push(item);
            // console.log(item);
            this.postParts(item);
          });
          //   console.log(this.rowsImport);
          // this.postParts();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          this.loading = false;
        })
        .then(() => {
          //   this.loading = false;
        });
    },
    importPackage: function() {
      this.loading = true;
      const formDataPackage = new FormData();
      formDataPackage.append("csvFile", this.filePackage);
      formDataPackage.append("branchId", this.userBranchId);
      formDataPackage.append("modelId", this.modelId.modelId);
      useNetw
        .post("api/import/package", formDataPackage)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.loading = false;
        })
        .then(() => {
            this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Service List</h4> -->

              <b-alert
                class="alert-fixed fixed-top rounded-1 m-4 bg-light"
                :show="dismissCountDown"
                dismissible
                fade
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
              >
                <p class="float-end">{{ dismissCountDown }} วินาที...</p>
                <p>แจ้งเตือน</p>
                <hr />
                <p>{{ resalert }}</p>
              </b-alert>

              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <code>** หมายเหตุ: กรุณาอัพโหลดไฟล์เฉพาะ .CSV</code>
                    <!-- <div class="row">
                      <div class="col-0 col-sm-0 col-md-3 mt-3"></div>
                      <div class="col-12 col-sm-6 col-md-6 mt-3">
                        <div class="card">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 m-4">
                              <b-form-file
                                v-model="filePart"
                                class="mt-3"
                                plain
                              ></b-form-file>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 m-4">
                              <button
                                class="btn btn-info"
                                @click="importData()"
                              >
                                อัพโหลดไฟล์
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-0 col-sm-0 col-md-3 mt-3"></div>
                    </div> -->
                    <div class="row">
                      <div class="col-0 col-sm-0 col-md-3 mt-3"></div>
                      <div class="col-12 col-sm-6 col-md-6 mt-3">
                        <div class="card">
                          <div class="row">
                            <div class="m-4">อัพโหลดไฟล์ Package</div>
                            <div class="col-6 col-sm-6 col-md-6 m-4">
                              <code> *</code> รุ่นรถ :
                              <multiselect
                                v-model="modelId"
                                label="nameEn"
                                :options="rowsModels"
                                :show-labels="false"
                                open-direction="bottom"
                                placeholder=""
                              >
                                <span slot="noResult">ไม่พบข้อมูล</span>
                              </multiselect>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 m-4">
                              <b-form-file
                                v-model="filePackage"
                                class="mt-3"
                                plain
                              ></b-form-file>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 m-4">
                              <button
                                class="btn btn-info"
                                @click="importPackage()"
                              >
                                อัพโหลดไฟล์
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-0 col-sm-0 col-md-3 mt-3"></div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
